// @flow strict
import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Column from '../components/column'
import Paragraph from '../components/paragraph'
import type { DefaultProps } from '../types'

type RemarkPost = {
  markdownRemark: {
    frontmatter: { title: string },
    html: string,
  },
}

export default ({ data, ...props }: DefaultProps<RemarkPost>) => {
  const post = data.markdownRemark
  return (
    <Layout {...props}>
      <Column
        width="50%"
        padding="5% 0"
        css={`
          > h1 {
            margin-bottom: 3rem;
          }
          * > p {
            line-height: 1.8;
          }
        `}
      >
        <h1>{post.frontmatter.title}</h1>
        <Paragraph dangerouslySetInnerHTML={{ __html: post.html }} />
      </Column>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
